import React from 'react';
import { Helmet } from 'react-helmet';
import logo from "../images/logo_iso.png";
import { useTranslation, Trans } from 'react-i18next';

export default function Front() {
    const { t } = useTranslation();

return (
    <div className="Frontpage">
        <Helmet>
            <meta name="description" content="Your description here" />
        </Helmet>
        <div className='logo-container'>
            <img src={logo} alt="" className='frontpage-logo'/>
        </div>
        
        <div className='Frontpage-content'>
            <p>{t('front.description')}</p>
        </div>
    </div>
)
}